import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// mui
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// state
import { useSelector } from "react-redux";
import store from "../store";
import {
  saveCustomers,
  saveUserList,
  saveVendorList,
  saveZipCodes,
  setLoading,
} from "../store/actions/CustomerActions";

// components
import MuiDataTable from "../components/tables/MuiDataTable";
import MainRootElement from "../components/layouts/MainRootElement";
import CustomerModel from "../models/CustomerModel";
import {
  fetchUserListService,
  fetchVendorsService,
  fetchZipcodesService,
} from "../services/CustomerServices";

function Central() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let [searchParams, setSearchParams] = useSearchParams();

  let pageType = { isCentralPage: true };
  let category = "To Be Assigned to Closer";
  let endpoint = "reversetolead";

  if (
    searchParams.get("category") === "assign-to-vendor" &&
    searchParams.get("tab") === "atv"
  ) {
    pageType = { isAssignVendor: true };
    category = "To Be Assigned to Vendor";
    endpoint = "reversetocloserassigned";
  }

  const [tableMainCategory, setTableMainCategory] = useState({
    category,
    endpoint,
  });

  const [tableSubCategory, setTableSubCategory] = useState("home-health");
  const [filterParam, setFilterParam] = useState(pageType);

  const { loading, zipCodes } = useSelector((state) => state.customer);

  const { user } = useSelector((state) => state.auth);

  const _urlParams = {
    process: "LE",
    pa: tableSubCategory,
    ...filterParam,
    limit: 100,
    skip: 0,
  };

  const _fetchCustomers = async () => {
    if (!loading) store.dispatch(setLoading(true));

    let newUrlParams = _urlParams;

    const assignedToCloserId = searchParams.get("assignedToCloserId");

    if (assignedToCloserId)
      newUrlParams.assignedToCloserId = assignedToCloserId;

    const res = await CustomerModel.fetchCustomers(newUrlParams);
    store.dispatch(saveCustomers(res));
  };

  const fetchZipCodes = async () => {
    const res = await fetchZipcodesService();
    store.dispatch(saveZipCodes(res));
  };

  useEffect(() => {
    const fetchUserList = async () => {
      const res = await fetchUserListService();
      const res2 = await fetchVendorsService();

      store.dispatch(saveUserList(res));
      store.dispatch(saveVendorList(res2));
    };

    if (zipCodes.length <= 0) {
      fetchZipCodes();
    }

    fetchUserList();
    _fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSubCategory, filterParam]);

  const handleClick = (e, value) => {
    let endpoint = "reversetolead";

    switch (value) {
      case "To Be Assigned to Closer":
        endpoint = "reversetolead";
        if (tableMainCategory !== value) {
          setFilterParam({ isCentralPage: true });
          setSearchParams({ tab: "tbstc", category: "assign-to-closer" });
        }
        break;

      case "Closer Assigned":
        endpoint = "reversetoassigncloser";
        if (tableMainCategory !== value) {
          setFilterParam({ isAssignedCloserPage: true });
          setSearchParams({ tab: "atc", category: "assign-to-closer" });
        }

        break;
      case "Wait List":
        endpoint = "reversetolead";
        if (tableMainCategory !== value) {
          setFilterParam({ isWaitPage: true });
          setSearchParams({ tab: "wait", category: "assign-to-closer" });
        }

        break;

      case "To Be Assigned to Vendor":
        endpoint = "reversetocloserassigned";
        if (tableMainCategory !== value) {
          setFilterParam({ isAssignVendor: true });
          setSearchParams({ tab: "atv", category: "assign-to-vendor" });
        }

        break;

      case "Vendor Assigned":
        endpoint = "reversetoassignvendor";
        if (tableMainCategory !== value) {
          setFilterParam({ isVendorAssigned: true });
          setSearchParams({ tab: "va", category: "vendor-assigned" });
        }

        break;

      case "Service Pending":
        endpoint = "reversetovendorassigned";
        if (tableMainCategory !== value) {
          setFilterParam({ isServicePending: true });
          setSearchParams({ tab: "cs", category: "confirm-service" });
        }

        break;

      default:
        break;
    }

    setTableMainCategory({ category: value, endpoint });
  };

  useEffect(() => {
    const data = { filterParam, tableMainCategory, tableSubCategory };
    localStorage.setItem("central", JSON.stringify(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSubCategory, filterParam]);

  return (
    <MainRootElement>
      <Box sx={{ backgroundColor: "#fff", pl: 1 }}>
        {user.accountType !== "vendor" ? (
          <Tabs
            value={tableMainCategory.category}
            onChange={handleClick}
            sx={{ minHeight: "35px" }}
          >
            {user.accountType !== "closer" ? (
              <Tab
                label="To Be Assigned to Closer"
                value="To Be Assigned to Closer"
                sx={{
                  minHeight: "35px",
                  padding: "20px 10px",
                  fontSize: "12px",
                  mr: "10px",
                }}
              />
            ) : null}

            {user.accountType !== "closer" ? (
              <Tab
                label="Closer Assigned"
                value="Closer Assigned"
                sx={{
                  minHeight: "35px",
                  padding: "20px 10px",
                  fontSize: "12px",
                  mr: "10px",
                }}
              />
            ) : null}

            {user.accountType !== "closer" ? (
              <Tab
                label="Wait List"
                value="Wait List"
                sx={{
                  minHeight: "35px",
                  padding: "20px 10px",
                  fontSize: "12px",
                  mr: "10px",
                }}
              />
            ) : null}

            <Tab
              label="To Be Assigned to Vendor"
              value="To Be Assigned to Vendor"
              sx={{
                minHeight: "35px",
                padding: "20px 10px",
                fontSize: "12px",
                mr: "10px",
              }}
            />

            <Tab
              label="Vendor Assigned"
              value="Vendor Assigned"
              sx={{
                minHeight: "35px",
                padding: "20px 10px",
                fontSize: "12px",
                mr: "10px",
              }}
            />

            {user.accountType !== "closer" ? (
              <Tab
                label="Service Pending"
                value="Service Pending"
                sx={{
                  minHeight: "35px",
                  padding: "20px 10px",
                  fontSize: "12px",
                  mr: "10px",
                }}
              />
            ) : null}
          </Tabs>
        ) : null}

        <Box sx={{ mb: 2 }}>
          <Tabs
            value={tableSubCategory}
            onChange={(e, value) => setTableSubCategory(value)}
            sx={{ minHeight: "35px" }}
          >
            <Tab
              label="Home Health"
              value="home-health"
              sx={{ minHeight: "35px", padding: "20px 10px", fontSize: "12px" }}
            />
            <Tab
              label="Hospice"
              value="hospice"
              sx={{ minHeight: "35px", padding: "20px 10px", fontSize: "12px" }}
            />
            <Tab
              label="Clinic"
              value="clinic"
              sx={{ minHeight: "35px", padding: "20px 10px", fontSize: "12px" }}
            />
            <Tab
              label="Signup"
              value="sign-up"
              sx={{ minHeight: "35px", padding: "20px 10px", fontSize: "12px" }}
            />
            <Tab
              label="ADHC"
              value="adhc"
              sx={{ minHeight: "35px", padding: "20px 10px", fontSize: "12px" }}
            />
            <Tab
              label="Pharmacy"
              value="pharmacy"
              sx={{ minHeight: "35px", padding: "20px 10px", fontSize: "12px" }}
            />
          </Tabs>
        </Box>

        <MuiDataTable />
      </Box>
    </MainRootElement>
  );
}

export default Central;
