import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function MultiSelectSearch2({
  label,
  onChange,
  options,
  variant,
  defaultValue,
}) {
  return (
    <Autocomplete
      multiple
      size="small"
      fullWidth={true}
      id="multi-select-search"
      options={options}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant ?? "standard"}
          label={label}
          placeholder={label}
        />
      )}
    />
  );
}
