/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// mui
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// state
import { useSelector } from "react-redux";
import store from "../../store";
import {
  updateUser,
  updateHealthPlans,
  setFormSubmitting,
  setLoading,
  setCustomers,
} from "../../store/actions/CustomerActions";

// forms
import CustomerForm from "../forms/CustomerForm";
import LeadForm from "../forms/LeadForm";
import AssignCategoryForm from "../forms/AssignCategoryForm";
import AssignToVendorForm from "../forms/AssignToVendorForm";
import ConfirmServiceForm from "../forms/ConfirmServiceForm";
import AssignToCloserForm from "../forms/AssignToCloserForm";
import VendorForm from "../forms/VendorForm";
import EditUserForm from "../forms/EditUserForm";
import NewHealthPlanForm from "../forms/NewHealthPlanForm";
import { createVendor, updateVendor } from "../../store/actions/VendorActions";
import {
  fetchCustomersService,
  updateCustomerService,
  updateHealthPlansService,
  updateUserService,
} from "../../services/CustomerServices";
import { PATIENT_ASSIGNMENT_LIST } from "../../constants/lists";
import {
  cerateNewVendorService,
  updateVendorService,
} from "../../services/VendorServices";
import InsuranceDocs from "../InsuranceDocs";
import config from "../../config/app";
import ManageUserForm from "../forms/ManageUserForm";

export default function SmallFormModalComponent(props) {
  console.log("Rendering Small Form Modal Component");
  const Navigation = useNavigate();
  const { formSubmitting, modal } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  const { customers, loading, loadMoreOptions, urlParams, totalRecords } =
    useSelector((state) => state.customer);

  const [maxWidth, setMaxWidth] = useState(modal.size);

  useEffect(() => {
    if (modal.size !== maxWidth) setMaxWidth(modal.size);
  }, [modal.size]);

  let [searchParams] = useSearchParams();

  const handlePrint = (customerId) => {
    console.log("customerId", customerId);
    const url =
      config.baseUrl +
      "/customer/pdf/" +
      customerId +
      "?i=1&token=" +
      user.token;
    const win = window.open(url);
    win.focus();
  };

  const handleForm = useCallback(
    async (formType, formData, saveAndPrint) => {
      try {
        // let newPayload = { ...formData };

        if (formData._id) {
          console.log("update");
        }

        // console.log("newPayload", newPayload);
        // return;

        if (!formSubmitting && !saveAndPrint)
          store.dispatch(setFormSubmitting(true));

        const res = await updateCustomerService(formData);

        if (saveAndPrint) {
          handlePrint(formData._id);
        }

        let _customers = customers;

        let payload = {};

        if (!formData._id) {
          _customers.unshift(res);

          payload = {
            totalRecords: res.total,
            customers: _customers,
            loadMoreOptions: {
              hasMore: customers.length < 90 ? false : true,
              skip: loadMoreOptions.skip ?? 100,
            },
            urlParams: urlParams,
          };
        } else {
          if (loading === false && !saveAndPrint) {
            store.dispatch(setLoading(true));
          }

          const res = await fetchCustomersService(urlParams, false);

          payload = {
            totalRecords: res.total,
            customers: res.customers,
            loadMoreOptions: {
              hasMore: res.customers.length < 90 ? false : true,
              skip: loadMoreOptions.skip ?? 100,
            },
            urlParams: urlParams,
          };
        }

        store.dispatch(setCustomers(payload));
        store.dispatch(setFormSubmitting(false));
        if (!saveAndPrint) props.closeModal();
      } catch (error) {
        store.dispatch(setFormSubmitting(false));
        console.log("error", error);
      }
    },
    [urlParams]
  );

  const handleVendorForm = useCallback(async (formType, formData) => {
    store.dispatch(setFormSubmitting(true));

    if (formData._id) {
      const res = await updateVendorService(formData);
      store.dispatch(updateVendor(formData, res));
    } else {
      const res = await cerateNewVendorService(formData);
      store.dispatch(createVendor(res));
    }

    props.closeModal();
  }, []);

  const handleEditUserForm = useCallback(async (formData) => {
    store.dispatch(setFormSubmitting(true));

    const res = await updateUserService(formData);

    store.dispatch(updateUser(formData, res));

    props.closeModal();
  }, []);

  const handleNewHealthPlanForm = useCallback(async (formData) => {
    store.dispatch(setFormSubmitting(true));

    const res = await updateHealthPlansService(formData);

    store.dispatch(updateHealthPlans(res));

    props.closeModal();
  }, []);

  const assignCategory = async (formType, formData) => {
    try {
      if (!formSubmitting) store.dispatch(setFormSubmitting(true));

      await updateCustomerService(formData);

      let _customers = [];
      let payload;

      // check if it lead or not
      if (
        formData &&
        formData.patientAssignment &&
        PATIENT_ASSIGNMENT_LIST.find(
          (paList) => paList.key === formData.patientAssignment
        )
      ) {
        store.dispatch(setFormSubmitting(false));
        setTimeout(() => {
          Navigation(`/lead?page=lead&id=${formData._id}`);
        }, 1000);

        return;
      } else {
        _customers = customers;

        const customerIndex = _customers.findIndex(
          (customer) => customer._id === formData._id
        );

        _customers[customerIndex] = { ...formData };

        payload = {
          totalRecords: totalRecords,
          customers: _customers,
          loadMoreOptions,
          urlParams,
        };
      }

      store.dispatch(setCustomers(payload));
      store.dispatch(setFormSubmitting(false));
      props.closeModal();
    } catch (error) {
      console.log(error);
      alert("Something went wrong while updating.");
      store.dispatch(setFormSubmitting(false));
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={props.modalOptions.size}
        open={props.modalOptions.open}
        onClose={props.closeModal}
      >
        {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          <Box
            noValidate
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
            }}
          >
            {props.modalOptions.for === "edit-user-form" && (
              <EditUserForm
                data={props.modalOptions.data}
                loading={formSubmitting}
                handleForm={handleEditUserForm}
                closeForm={props.closeModal}
              />
            )}

            {props.modalOptions.for === "manage-user-form" && (
              <ManageUserForm
                data={props.modalOptions.data}
                loading={formSubmitting}
                handleForm={handleEditUserForm}
                closeForm={props.closeModal}
              />
            )}

            {props.modalOptions.for === "create-vendor-form" && (
              <VendorForm
                data={props.modalOptions.data}
                loading={formSubmitting}
                handleForm={handleVendorForm}
                closeForm={props.closeModal}
              />
            )}

            {props.modalOptions.for === "customer-form" && (
              <CustomerForm
                loading={formSubmitting}
                data={props.modalOptions.data}
                handleForm={handleForm}
                closeForm={props.closeModal}
              />
            )}

            {props.modalOptions.for === "lead-form" && (
              <LeadForm
                loading={formSubmitting}
                data={props.modalOptions.data}
                handleForm={handleForm}
                closeForm={props.closeModal}
                user={user}
              />
            )}

            {props.modalOptions.for === "category-form" && (
              <AssignCategoryForm
                loading={formSubmitting}
                data={props.modalOptions.data}
                handleForm={assignCategory}
                closeForm={props.closeModal}
              />
            )}

            {props.modalOptions.for === "assign-to-closer" &&
              searchParams.get("category") === "assign-to-vendor" && (
                <AssignToVendorForm
                  loading={formSubmitting}
                  data={props.modalOptions.data}
                  handleForm={handleForm}
                  closeForm={props.closeModal}
                />
              )}

            {props.modalOptions.for === "assign-to-closer" &&
              searchParams.get("category") === "confirm-service" && (
                <ConfirmServiceForm
                  loading={formSubmitting}
                  data={props.modalOptions.data}
                  handleForm={handleForm}
                  closeForm={props.closeModal}
                />
              )}

            {props.modalOptions.for === "assign-to-closer" &&
              searchParams.get("category") === "assign-to-closer" && (
                <AssignToCloserForm
                  loading={formSubmitting}
                  data={props.modalOptions.data}
                  handleForm={handleForm}
                  closeForm={props.closeModal}
                />
              )}

            {props.modalOptions.for === "new-health-plan" && (
              <NewHealthPlanForm
                loading={formSubmitting}
                data={props.modalOptions.data}
                handleForm={handleNewHealthPlanForm}
                closeForm={props.closeModal}
              />
            )}

            {props.modalOptions.for === "show-docs" && (
              <InsuranceDocs
                user={user}
                data={props.modalOptions.data}
                closeForm={props.closeModal}
              />
            )}
          </Box>
        </DialogContent>
        {/*   <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
