import React from "react";
import { useSelector } from "react-redux";

const LGFilter = ({ textFilter }) => {
  const [selected, setSelected] = React.useState("");
  const { staffs, loading } = useSelector((state) => state.customer);

  const handleSelect = (e) => {
    //   filterStaff
    console.log("e", e.target.value);
    setSelected(e.target.value);
    textFilter("filterStaff", e.target.value);
  };

  if (loading) {
    return <div style={{ width: 150, height: 27 }}></div>;
  }

  return (
    <select className="select" onChange={handleSelect}>
      <option>All</option>
      {staffs &&
        staffs.length > 0 &&
        staffs.map((user) => (
          <option
            key={user._id}
            value={user.email}
            selected={selected === user.email ? true : false}
          >
            {user.fname} {user.lname}
          </option>
        ))}
    </select>
  );
};

export default React.memo(LGFilter);
